import React from "react";

export const PaymentModal = () => {
    return (
        <div id="paymentModal" className="modal fade">
            <div className="modal-dialog modal-confirm modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                        <iframe name="creditCardformFrame" width="100%" height="100%" frameBorder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}