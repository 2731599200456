import ProductService from "../../services/product.service";
import {productConstants} from "../constants";

import {confirm_alert} from "../../shared/Alert/ConfirmAlert";

export const getProductInit = () => (dispatch) => {
    return ProductService.getProductInit().then(
        (result) => {
            dispatch({
                type: productConstants.GET_PRODUCT_INIT_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_PRODUCT_INIT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getProductComments = (urun_no, uref, last_id) => (dispatch) => {
    return ProductService.getProductComments(urun_no, uref, last_id).then(
        (result) => {
            dispatch({
                type: productConstants.GET_PRODUCT_COMMENTS_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_PRODUCT_COMMENTS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const clearProductSubSection = () => (dispatch) => {
    dispatch({
        type: productConstants.CLEAR_PRODUCT_SUBSECTION,
    });
};

export const addComment = (data) => (dispatch) => {
    return ProductService.addComment(data).then(
        (result) => {
            dispatch({
                type: productConstants.INSERT_COMMENT_SUCCESS,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.INSERT_COMMENT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const editComment = (data) => (dispatch) => {
    return ProductService.editComment(data).then(
        (result) => {
            dispatch({
                type: productConstants.UPDATE_COMMENT_SUCCESS,
                payload: data
            });

            confirm_alert(null,"Güncellendi", " Yorumunuz baişarıyla güncellendi.")


            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.UPDATE_COMMENT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getProductQuestions = (urun_no, uref, last_id) => (dispatch) => {
    return ProductService.getProductQuestions(urun_no, uref, last_id).then(
        (result) => {
            dispatch({
                type: productConstants.GET_QUESTIONS_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_QUESTIONS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const askQuestion = (data) => (dispatch) => {
    return ProductService.askQuestion(data).then(
        (result) => {
            dispatch({
                type: productConstants.ASK_QUESTION_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.ASK_QUESTION_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getOpportunityProducts = () => (dispatch) => {
    return ProductService.getOpportunityProducts().then(
        (result) => {
            dispatch({
                type: productConstants.GET_OPPORTUNITIES_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_OPPORTUNITIES_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const addToLastVisited = (productId) => {
    return {
        type: productConstants.ADD_TO_LAST_VISITED_PRODUCTS,
        payload: {
            productId
        }
    }
}

export const getWaitProducts = () => (dispatch) => {
    return ProductService.getWaitProducts().then(
        (result) => {
            dispatch({
                type: productConstants.GET_WAIT_PRODUCTS_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_WAIT_PRODUCTS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setWaitProducts = (urun_no) => (dispatch) => {
    return ProductService.setWaitProducts(urun_no).then(
        (result) => {
            dispatch({
                type: productConstants.SET_WAIT_PRODUCT_SUCCESS,
                payload: urun_no,
            });

            confirm_alert(null,"Talebiniz Alınmıştır", " Beklediğiniz ürün geldiğinde SMS ve E-Posta yolu ile sizi bilgilendireceğiz. " +
                "Bu ürünleri Hesabım Beklediğim Ürünler   menüsünden takip edebilirsiniz.")

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.SET_WAIT_PRODUCT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const deleteWaitProducts = (urun_no) => (dispatch) => {
    return ProductService.deleteWaitProducts(urun_no).then(
        (result) => {
            dispatch({
                type: productConstants.DELETE_WAIT_PRODUCT_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.DELETE_WAIT_PRODUCT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getCargoProviders = () => (dispatch) => {
    return ProductService.getCargoProviders().then(
        (result) => {
            dispatch({
                type: productConstants.GET_CARGO_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {
            dispatch({
                type: productConstants.GET_CARGO_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getWaitingComments = () => (dispatch) => {
    return ProductService.getWaitingComments().then(
        (result) => {
            dispatch({
                type: productConstants.GET_WAITING_COMMENTS_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_WAITING_COMMENTS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getOldComments = () => (dispatch) => {
    return ProductService.getOldComments().then(
        (result) => {
            dispatch({
                type: productConstants.GET_OLD_COMMENTS_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_OLD_COMMENTS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getProductDetail =  (product_id) => (dispatch) => {
    return ProductService.getProductDetail(product_id).then(
        (result) => {

            dispatch({
                type: productConstants.GET_PRODUCT_DETAIL_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: productConstants.GET_PRODUCT_DETAIL_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setQuickLookModalData = (product) => (dispatch) => {
    dispatch({
        type: productConstants.SET_QUICKLOOKMODAL_DATA,
        payload: {product}
    });
}
