import {productConstants} from "../constants";

const lastVisitedProducts = JSON.parse(localStorage.getItem('lastVisitedProducts'))

const initialState = {
    products: null,
    comments: null,
    questions: null,
    campaigns: null,
    opportunities: null,
    waitProducts: [],
    waitingComments: null,
    oldComments: null,
    productFilters: null,
    productDetail: null,
    cargoProviders: null,
    oppProducts: null,
    quickLookModalData: null,
    lastVisitedProducts: lastVisitedProducts ? lastVisitedProducts : []
};

export default function productReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case productConstants.GET_PRODUCT_INIT_SUCCESS:
            return {
                ...state,
                products: payload.products,
                campaigns: payload.campaigns,
                productFilters: payload.filters,
            };
        case productConstants.GET_PRODUCT_INIT_FAIL:
            return {
                ...state,
                products: null,
                campaigns: null,
                productFilters: null
            };
        case productConstants.GET_PRODUCT_COMMENTS_SUCCESS:
            if (state.comments?.length) {
                return {
                    ...state,
                    comments: [...state.comments, ...payload.data]
                };
            } else {
                return {
                    ...state,
                    comments: payload.data
                };
            }
        case productConstants.GET_PRODUCT_COMMENTS_FAIL:
            return {
                ...state,
                comments: null
            };
        case productConstants.CLEAR_PRODUCT_SUBSECTION:
            return {
                ...state,
                questions: [],
                comments: []
            };
        case productConstants.GET_WAITING_COMMENTS_SUCCESS:
            return {
                ...state,
                waitingComments: payload
            };
        case productConstants.GET_WAITING_COMMENTS_FAIL:
            return {
                ...state,
                waitingComments: null
            };
        case productConstants.GET_OLD_COMMENTS_SUCCESS:
            return {
                ...state,
                oldComments: payload
            };
        case productConstants.GET_OLD_COMMENTS_FAIL:
            return {
                ...state,
                oldComments: null
            };
        case productConstants.UPDATE_COMMENT_SUCCESS:
            const comments = [...state.oldComments];
            const commentKey = comments.findIndex(a => a._id === payload.id);
            const comment = comments[commentKey]
            comments[commentKey] = {...comment, ...payload};
            return {
                ...state,
                oldComments: comments
            };
        case productConstants.UPDATE_COMMENT_FAIL:
            return {
                ...state,
                oldComments: null
            };
        case productConstants.GET_QUESTIONS_SUCCESS:
            if (state.questions?.length) {
                return {
                    ...state,
                    questions: [...state.questions, ...payload]
                };
            } else {
                return {
                    ...state,
                    questions: payload
                };
            }
        case productConstants.GET_QUESTIONS_FAIL:
            return {
                ...state,
                questions: null
            };
        case productConstants.ASK_QUESTION_SUCCESS:
            return {
                ...state,
                questions: [...state.questions, payload]
            };
        case productConstants.ASK_QUESTION_FAIL:
            return {
                ...state
            };
        case productConstants.GET_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                opportunities: payload
            };
        case productConstants.GET_OPPORTUNITIES_FAIL:
            return {
                ...state,
                opportunities: null
            };
        case productConstants.GET_WAIT_PRODUCTS_SUCCESS:
            return {
                ...state,
                waitProducts: [...payload?.split(',')]
            };
        case productConstants.GET_WAIT_PRODUCTS_FAIL:
            return {
                ...state,
                waitProducts: null
            };
        case productConstants.SET_WAIT_PRODUCT_SUCCESS:
            return {
                ...state,
                waitProducts: [...state.waitProducts, payload]
            };
        case productConstants.SET_WAIT_PRODUCT_FAIL:
            return {
                ...state
            };
        case productConstants.DELETE_WAIT_PRODUCT_SUCCESS:
            const waitProducts = state.waitProducts.filter((p) => p !== payload);
            return {
                ...state,
                waitProducts: [...waitProducts]
            };
        case productConstants.DELETE_WAIT_PRODUCT_FAIL:
            return {
                ...state
            };
        case productConstants.GET_CARGO_SUCCESS:
            return {
                ...state,
                cargoProviders: payload
            };
        case productConstants.GET_CARGO_FAIL:
            return {
                ...state,
                cargoProviders: null
            };
        case productConstants.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                productDetail: payload
            };
        case productConstants.GET_PRODUCT_DETAIL_FAIL:
            return {
                ...state,
                productDetail: null
            };
        case productConstants.SET_OPPORTUNITY_PRODUCTS:
            return {
                ...state,
                oppProducts: payload,
            };
        case productConstants.SET_QUICKLOOKMODAL_DATA:
            return {
                ...state,
                quickLookModalData: payload,
            }
        case productConstants.ADD_TO_LAST_VISITED_PRODUCTS:
            let lastVisitedProducts = state.lastVisitedProducts;
            if (lastVisitedProducts.find(p => p._id === payload.productId) === undefined) {

                const product = state.products?.find(p => p._id === payload.productId);
                if (product) {
                    lastVisitedProducts.push(product);
                }

                if (lastVisitedProducts.length > 8) {
                    lastVisitedProducts.shift();
                }
                localStorage.setItem('lastVisitedProducts', JSON.stringify(lastVisitedProducts));
            }
            return {
                ...state
            };
        default:
            return state;
    }
}