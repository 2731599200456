import {cartConstants, userConstants} from "../constants";
import dayjs from "dayjs";



const initialState = {
    userProfile: {},
    favorites: [],
    coupons: [],
    points: [],
    preferences: {},
    questions: [],
    beautyProfile: [],
    userBeautyProfile: null,
    contract: null
};

export default function userReducer(state = initialState, action) {
    const {type, payload} = action;
    const result = payload?.result;

    switch (type) {
        case userConstants.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {...payload, dTarih: dayjs(payload.dTarih).format('YYYY-MM-DD')},
            };
        case userConstants.GET_USER_PROFILE_FAIL:
            return {
                ...state,
                userProfile: {}
            };
        case userConstants.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile, ...payload,
                    dTarih: dayjs(payload.dTarih).format('YYYY-MM-DD')
                },
            };
        case userConstants.UPDATE_USER_PROFILE_FAIL:
            return {
                ...state,
                userProfile: {}
            };
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state
            };
        case userConstants.FORGOT_PASSWORD_FAIL:
            return {
                ...state
            };
        case userConstants.GET_ALL_FAVORITES_SUCCESS:
            return {
                ...state,
                favorites: payload ? payload : []
            };
        case userConstants.GET_ALL_FAVORITES_FAIL:
            return {
                ...state,
                favorites: []
            };
        case userConstants.SET_FAVORITE_SUCCESS:
            return {
                ...state,
                favorites: [...state.favorites, payload]
            };
        case userConstants.SET_FAVORITE_FAIL:
            return {
                ...state
            };
        case userConstants.DELETE_FAVORITE_SUCCESS:
            const newFavorites = state.favorites.filter(item => item !== payload);
            return {
                ...state,
                favorites: newFavorites
            };
        case userConstants.DELETE_FAVORITE_FAIL:
            return {
                ...state
            };
        case userConstants.GET_COUPONS_SUCCESS:
            return {
                ...state,
                coupons: payload
            };
        case userConstants.GET_COUPONS_FAIL:
            return {
                ...state,
                coupons: null
            };
        case cartConstants.GET_CART:
            return {
                ...state,
                coupons: result.couponList
            };
        case userConstants.GET_POINTS_SUCCESS:
            return {
                ...state,
                points: payload
            };
        case userConstants.GET_POINTS_FAIL:
            return {
                ...state,
                points: null
            };
        case userConstants.CREATE_COUPON_SUCCESS:
            return {
                ...state
            };
        case userConstants.CREATE_COUPON_FAIL:
            return {
                ...state
            };
        case userConstants.GET_BEAUTY_PROFILE_SUCCESS:
            return {
                ...state,
                beautyProfile: [...payload]
            };
        case userConstants.GET_BEAUTY_PROFILE_FAIL:
            return {
                ...state,
                beautyProfile: null
            };
        case userConstants.SET_BEAUTY_PROFILE_SUCCESS:
            return {
                ...state
            };
        case userConstants.SET_BEAUTY_PROFILE_FAIL:
            return {
                ...state
            };
        case userConstants.GET_USER_BEAUTY_PROFILE_SUCCESS:
            return {
                ...state,
                userBeautyProfile: payload
            };
        case userConstants.GET_USER_BEAUTY_PROFILE_FAIL:
            return {
                ...state,
                userBeautyProfile: null
            };
        case userConstants.GET_PREFERENCES_SUCCESS:
            return {
                ...state,
                preferences: {...payload}
            };
        case userConstants.GET_PREFERENCES_FAIL:
            return {
                ...state,
                preferences: null
            };
        case userConstants.GET_ASKED_QUESTIONS_SUCCESS:
            return {
                ...state,
                questions: payload
            };
        case userConstants.GET_ASKED_QUESTIONS_FAIL:
            return {
                ...state,
                questions: null
            };
        case userConstants.GET_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: payload
            };
        case userConstants.GET_CONTRACT_FAIL:
            return {
                ...state,
                contract: null
            };
        case userConstants.SAVE_PREFERENCES_SUCCESS:
            return {
                ...state,
                preferences: {...payload}
            };
        case userConstants.SAVE_PREFERENCES_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}