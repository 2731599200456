import React from 'react'

const QuantityDropdown = ({classes, showChoose = false, quantity, maxNumber = 10, quantityChanged}) => {
    return (
            <select className={classes} name="" id=""
                    value={quantity}
                    onChange={(e) => {
                        quantityChanged(e)
                    }}>
                {showChoose && (
                    <option value="">Adet Seçiniz</option>
                )}
                {[...Array(Math.abs(maxNumber))].map((item, i) => {
                    return (
                        <option value={i + 1} key={i}>{i + 1} Adet</option>
                    )
                })}
            </select>

    )
}

export default QuantityDropdown;
