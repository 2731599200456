import http from "../utils/HttpCommon";

class ProductService {

    getProductInit() {
        return http.post("/init").then((result) => {
            return result.data.response;
        });
    }

    getUserInit() {
        return http.post("/getUserInit").then((result) => {
            return result.data.response;
        });
    }

    getProductComments(urun_no, uref, last_id,type='self',loadType='comments') {
        let url;

        if (last_id) {
            url = `/productComments?urun_no=${urun_no}&uref=${uref}&last_id=${last_id}&type=${type}&loadType=${loadType}`
        } else {
            url = `/productComments?urun_no=${urun_no}&uref=${uref}&type=${type}&loadType=${loadType}`
        }

        return http.get(url).then((result) => {
            return result.data.response;
        });
    }

    addComment(data) {
        return http.post("/insertComment", data).then((result) => {
            return result.data.response;
        });
    }

    editComment(data) {
        return http.post("/updateComment", data).then((result) => {
            return result.data.response;
        });
    }

    getProductQuestions(urun_no, uref, last_id) {
        let url;

        if (last_id) {
            url = `/productQuestions?urun_no=${urun_no}&uref=${uref}&last_id=${last_id}`
        } else {
            url = `/productQuestions?urun_no=${urun_no}&uref=${uref}`
        }

        return http.get(url).then((result) => {
            return result.data.response;
        });
    }

    askQuestion(data) {
        return http.post("/askQuestion", data).then((result) => {
            return result.data.response;
        });
    }

    getOpportunityProducts() {
        return http.post("/getOpportunityProducts").then((result) => {
            return result.data.response;
        });
    }

    getWaitProducts() {
        return http.post("/getWaitProducts").then((result) => {
            return result.data.response;
        });
    }

    setWaitProducts(urun_no) {
        return http.post("/setWaitProducts", {urun_no}).then((result) => {
            return result.data.response;
        });
    }

    deleteWaitProducts(urun_no) {
        return http.post("/deleteWaitProducts", {urun_no}).then((result) => {
            return result.data.response;
        });
    }

    getCargoProviders() {
        return http.post("/getCargo").then((result) => {
            return result.data.response;
        });
    }

    getWaitingComments() {
        return http.post("/waitComment").then((result) => {
            return result.data.response;
        });
    }

    getOldComments() {
        return http.post("/oldComment").then((result) => {
            return result.data.response;
        });
    }

    getProductDetail(id) {
        return http.get(`/productDetail?id=${id}`).then((result) => {
            return result.data.response;
        });
    }
}

export default new ProductService();