import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {rootReducer} from "./reducers";
import {createStateSyncMiddleware} from "redux-state-sync";
import jwtDecode from "jwt-decode";


const config = {
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"]
};

const checkTokenExpirationMiddleware = store => next => action => {
    const token =
        JSON.parse(localStorage.getItem("misshaUser")) &&
        JSON.parse(localStorage.getItem("misshaUser"))["token"];

    if (token) {
        if (jwtDecode(token).exp < Date.now() / 1000) {
            next(action);
            localStorage.clear();
        }
    }
    next(action);
};


export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        checkTokenExpirationMiddleware,
        createStateSyncMiddleware(config)
    )
);