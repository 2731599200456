import React from 'react'
import './Alert.scss';
import {TIMER_VALUE} from "../../constants/Constants";


export const alert = (alertType, message) => {

    const alertModal = document.getElementById("alertModal");
    if (alertModal) {
        alertModal.remove();
    }

    const root = document.createElement('div')
    root.id = "alertModal"

    const startBtn = document.createElement("button");
    startBtn.className = "btn btn-primary";
    startBtn.type = "button";
    startBtn.id = "openAlert";
    startBtn.setAttribute("style", "display:none")
    startBtn.setAttribute("data-toggle", "modal")
    startBtn.setAttribute("data-target", "#myModal")

    root.appendChild(startBtn);

    const modal = document.createElement('div')
    modal.id = 'myModal';
    modal.className = 'modal fade';

    const dialog = document.createElement('div');
    dialog.className = "modal-dialog modal-confirm modal-sm"
    modal.appendChild(dialog)

    const content = document.createElement('div');
    content.className = "modal-content"
    dialog.appendChild(content)

    const header = document.createElement('div');
    header.className = "modal-header"
    content.appendChild(header)

    const iconBox = document.createElement('div');

    if (alertType === "success") {
        iconBox.className = "icon-box bg-success"
    } else if (alertType === "error") {
        iconBox.className = "icon-box bg-danger"
    } else {
        iconBox.className = "icon-box bg-warning"
    }

    header.appendChild(iconBox)

    const icon = document.createElement('i');

    if (alertType === "success") {
        icon.className = "ri-check-line";
    } else if (alertType === "error") {
        icon.className = "ri-close-line";
    } else {
        icon.className = "ri-error-warning-line";
    }

    iconBox.appendChild(icon)

    const title = document.createElement('h4');
    title.className = "modal-title w-100"
    if (alertType === 'success') {
        title.innerText = "Başarılı";
    } else if (alertType === "error") {
        title.innerText = "Hata";
    } else {
        title.innerText = "Uyarı";
    }

    header.appendChild(title)

    const body = document.createElement('div');
    body.className = "modal-body"
    content.appendChild(body)

    const text = document.createElement('p');
    text.className = "text-center message-text"
    text.innerText = message;
    body.appendChild(text)

    const footer = document.createElement('div');
    footer.className = "modal-footer"

    const okButton = document.createElement('button');

    if (alertType === 'success') {
        okButton.className = "btn btn-success btn-block"
    } else if (alertType === "error") {
        okButton.className = "btn btn-danger btn-block"
    } else {
        okButton.className = "btn btn-warning btn-block"
    }
    okButton.id = "closeAlert";
    okButton.setAttribute("data-dismiss", "modal");
    okButton.innerText = "Tamam"
    footer.appendChild(okButton)

    content.appendChild(footer)

    root.appendChild(modal);

    document.body.appendChild(root);
    const openBtn = document.getElementById("openAlert");
    const closeBtn = document.getElementById("closeAlert");
    openBtn.click();

    setTimeout(() => {
       // closeBtn.click();
    }, TIMER_VALUE)
}

