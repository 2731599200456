import {connect} from "react-redux";
import React from "react";
import {deleteFavorite, setFavorite} from "../../store/actions/user.actions";
import {useHistory} from "react-router-dom";
import {addToCart} from "../../store/actions/cart.actions";
import Stars from "../../utils/Stars";
import {ProductPriceSection} from "../ProductPriceSection/ProductPriceSection";
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';


const OpportunityModal = ({
                              cdn,
                              data,
                              products,
                              favorites,
                              campaigns,
                              isLoggedIn,
                              addToCart,
                              setFavorite,
                              deleteFavorite
                          }) => {

    const history = useHistory();
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            loop: false,
            mode: "free-snap",
            slides: {
                perView: 2,
                spacing: 7,
            },
        },
        [
            // add plugins here
        ]
    )
    if(cdn && data && products && favorites){
    return (
        <div className="modal fade" id="opportunityModal"
             tabIndex="-1" role="dialog" aria-labelledby="modelTitleId"
             aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Sepete Eklediğin Ürünlerde Fırsat Var!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <p className="font-weight-normal text-muted mb-2">{data?.campaign?.kmp_adi}</p>
                            <p className="mb-0 font-weight-bold text-danger">Kampanyaya Dahil Ürünler</p>
                            <hr className="border-dark my-2"/>

                            <div className="keen-slider" ref={sliderRef}>
                                { data.productsToShow.map((product, i) => {
                                    return (
                                            <div className="offerProducts" key={`opp-${i}`}>
                                                <div className="offerProducts-Item">
                                                    <div className="offerProducts-Item-Like">
                                                        <div>
                                                            {favorites && (favorites.includes(product?._id) ? (
                                                                <i className="ri-heart-fill text-danger"
                                                                   onClick={(e) => {
                                                                       e.stopPropagation();
                                                                       deleteFavorite(product?._id)
                                                                   }}/>
                                                            ) : (
                                                                <i className="ri-heart-fill"
                                                                   onClick={(e) => {
                                                                       e.stopPropagation();
                                                                       setFavorite(product?._id)
                                                                   }}/>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <img className="lazyload img-fluid align-self-center"
                                                         src={`${cdn}/urunler/${product?._id}/prd/L${product?._id}.jpg`}
                                                         alt=""/>
                                                    <div
                                                        className="offerProducts-Item-Vote d-flex justify-content-between flex-column flex-md-row mb-2 ">
                                                        <div className="offerProducts-Item-Vote-Stars">
                                                            <Stars n={product?.y}/>
                                                        </div>
                                                        <div className="offerProducts-Item-Vote-CommentsCount">
                                                            ({product.ys} Yorum)
                                                        </div>
                                                    </div>
                                                    <a href="/#" onClick={(e) => {
                                                        e.preventDefault()
                                                        history.push(`/urun-detay/${product.qs ?? 'urun'}/${product?._id}`)
                                                        window.closeModal("opportunityModal");
                                                    }} className="h4 offerProducts-Item-Title">
                                                        {product?.marka}{product?.ad}
                                                    </a>
                                                    <p className="mb-2">{product?.tr}</p>
                                                    <a name="" id=""
                                                       className="InstockNotification btn btn-outline-warning border-bottom-0 border-right-0 border-left-0 font-weight-normal rounded-0 d-flex align-items-center justify-content-center mt-auto"
                                                       href="/#" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        addToCart(product, products, 1, false, isLoggedIn, campaigns)
                                                    }} role="button">

                                                        Sepete Ekle
                                                    </a>
                                                    <ProductPriceSection product={product}/>
                                                </div>
                                            </div>
                                    );
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mx-auto">
                        <a href="/#" onClick={(e) => {
                            e.preventDefault()
                            window.closeModal("opportunityModal");
                            history.push(`/kampanyalar/urunler/` + data?.campaign?._id)
                        }} className="btn btn-danger" role="button">
                            TÜMÜNÜ GÖR
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )}
}

const mapDispatchToProps = {
    deleteFavorite,
    setFavorite,
    addToCart
};

const mapStateToProps = (state) => {
    return {
        cdn: state.menu?.mainConstants?.cdn,
        data: state.product?.oppProducts,
        favorites: state.user?.favorites,
        products: state.product.products,
        campaigns: state.product.campaigns?.data,
        isLoggedIn: state.authentication.isLoggedIn
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityModal);
