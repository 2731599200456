export const getOpportunityProducts = (product, products, campaigns) => {
    let kmpId = null;

    if (product.kHRef > 0) {
        kmpId = product.kHRef;
    } else if (product.uKRef > 0) {
        kmpId = product.uKRef;
    }

    if (kmpId) {
        const campaign = campaigns.find(p => p._id === kmpId);
        const isIncluded = [1, 2, 3].includes(Number(campaign.kmp_tip));
        if (isIncluded) {
            let productsToShow;

            if (campaign.kmp_tip === 1) {
                productsToShow = products.filter(p => p.kHRef === campaign._id && p.stok > 0);
            } else {
                productsToShow = products.filter(p => p.uKRef === campaign._id && p.stok > 0);
            }

            return {productsToShow, campaign};
        }
    } else {
        return null;
    }
}