import {menuConstants} from "../constants";

const initialState = {
    mainConstants: null,
    mainMenu: null,
    userInit: null,
    menuInit: null,
    mobileMenu: {},
    middleMenuOpen: false,
    showMobileFooter: true,
    selectedPageTitle: null,
    activeProfileTab: null
};

export default function menuReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case menuConstants.GET_MENU_SUCCESS:
            return {
                ...state,
                mainMenu: payload.menu,
                mainConstants: payload,
                userInit: payload.userInit
            };
        case menuConstants.GET_MENU_FAIL:
            return {
                ...state,
                mainMenu: null,
                mainConstants: null
            };
        case menuConstants.GET_MOBILE_MENU_SUCCESS:
            return {
                ...state,
                mobileMenu: payload
            };
        case menuConstants.GET_MOBILE_MENU_FAIL:
            return {
                ...state,
                mobileMenu: null
            };

        case menuConstants.TOGGLE_MIDDLE_MENU:
            const overlay = document.getElementById('overlay')
            const menuState = !state.middleMenuOpen;

            if (menuState) {
                overlay.style.display = 'block'
            } else {
                overlay.style.display = 'none'
            }

            return {
                ...state,
                middleMenuOpen: !state.middleMenuOpen
            };
        case menuConstants.SHOW_MOBILE_FOOTER:
            return {
                ...state,
                showMobileFooter: payload
            };
        case menuConstants.SET_SELECTED_PAGE_MENU_TITLE:
            return {
                ...state,
                selectedPageTitle: payload
            };
        case menuConstants.SET_ACTIVE_PROFILE_TAB:
            return {
                ...state,
                activeProfileTab: payload
            };
        default:
            return state;
    }
}