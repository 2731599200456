import http from "../utils/HttpCommon";

class LocationService {
    getAddresses() {
        return http.post("/getAddress").then((result) => {
            return result.data.response;
        });
    }


    addAddress(address) {
        return http.post("/addToAddress", address).then((result) => {
            return result.data.response;
        });
    }

    updateAddress(address) {
        return http.post("/updateAddress", address).then((result) => {
            return result.data.response;
        });
    }

    deleteAddress(adres_no) {
        return http.post("/deleteAddress", {adres_no}).then((result) => {
            return result.data.response;
        });
    }

    setDefaultAddress(adres_no) {
        return http.post("/defaultAdress", {adres_no}).then((result) => {
            return result.data.response;
        });
    }

    getCities() {
        return http.post("/city").then((result) => {
            return result.data.response;
        });
    }

    getCounties(il_id) {
        return http.post(`/state?il_id=${il_id}`).then((result) => {
            return result.data.response.ilce;
        });
    }
}

export default new LocationService();