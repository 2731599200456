import React from "react";

export const ProductPriceSection = ({ product }) => {
  return (
    <div className="d-flex flex-row justify-content-between mt-auto">
      {product.kmpBilgi && product.stok > 0 ? (
        <>
          {product.kmpBilgi.ki > 0 && product.kmpBilgi.si > 0 ? (
            <>
              <div className="offerProducts-Item-Price text-left">
                <div className="offerProducts-Item-Price-Old">{product?.sf}₺</div>
                <div className="offerProducts-Item-Price-Default font-weight-normal">{(product?.sf - product.kmpBilgi.ki).toFixed(2)}₺</div>
              </div>
              <div className="awwardedProducts-Item-extraDiscount">
                <span>{product.kmpBilgi.s}</span>
                <p className=" offerProducts-Item-Price-Default  text-danger mb-0">
                  {(product?.sf - (product.kmpBilgi.ki + product.kmpBilgi.si)).toFixed(2)}₺
                </p>
              </div>
            </>
          ) : (
            <>
              {product.kmpBilgi.ki > 0 ? (
                <div className="offerProducts-Item-Price text-left">
                  <div className="offerProducts-Item-Price-Old">{product?.sf}₺</div>
                  <div className="offerProducts-Item-Price-Default font-weight-normal">{(product?.sf - product.kmpBilgi.ki).toFixed(2)}₺</div>
                </div>
              ) : (
                <>
                  {product.kmpBilgi.si > 0 ? (
                    <>
                      <div className="offerProducts-Item-Price-Default mt-3">{product?.sf}₺.</div>
                      <div className="awwardedProducts-Item-extraDiscount">
                        <span>{product.kmpBilgi.s}</span>
                        <p className=" offerProducts-Item-Price-Default font-weight-bold  text-danger mb-0">
                          {(product?.sf - (product.kmpBilgi.ki + product.kmpBilgi.si)).toFixed(2)}₺
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-start align-items-center mt-1">
                      <div className="offerProducts-Item-Price-Old">1734.9₺</div>
                      <div className="offerProducts-Item-Price-Default text-center mt-1">{product?.sf} ₺</div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
