import {locationConstants} from "../constants";

const initialState = {addresses: [], cities: [], counties: [], neighborhoods: []};

export default function locationReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case locationConstants.GET_ADDRESS_SUCCESS:
            return {
                ...state,
                addresses: payload
            };
        case locationConstants.GET_ADDRESS_FAIL:
            return {
                ...state,
                addresses: null
            };
        case locationConstants.DELETE_ADDRESS_SUCCESS:
            const filteredAddress = state.addresses.filter((a) => a._id !== payload);
            return {
                ...state,
                addresses: filteredAddress
            };
        case locationConstants.DELETE_ADDRESS_FAIL:
            return {
                ...state
            };
        case locationConstants.SET_DEFAULT_ADDRESS_SUCCESS:
            const addressList = [...state.addresses];
            addressList.map(a => a.default = 0);
            const adressIndex = addressList.findIndex(a => a._id === payload);
            addressList[adressIndex].default = 1;
            return {
                ...state,
                addresses: addressList,
            };
        case locationConstants.SET_DEFAULT_ADDRESS_FAIL:
            return {
                ...state
            };
        case locationConstants.GET_CITIES_SUCCESS:
            return {
                ...state,
                cities: payload
            };
        case locationConstants.GET_CITIES_FAIL:
            return {
                ...state,
                cities: null
            };
        case locationConstants.GET_COUNTIES_SUCCESS:
            return {
                ...state,
                counties: payload
            };
        case locationConstants.GET_COUNTIES_FAIL:
            return {
                ...state,
                counties: null
            };
        default:
            return state;
    }
}