import http from "../utils/HttpCommon";

class UserService {

    getUserProfile() {
        return http.post("/getProfile").then((result) => {
            return result.data;
        });
    }

    updateUserProfile(data) {
        return http.post("/updateUser", data).then((result) => {
            return result.data;
        });
    }

    forgotPass(email, phone) {
        let data = email ? {email} : {phone};
        return http.post("/forgetPassword", data).then((result) => {
            return result.data;
        });
    }

    getUserFav() {
        return http.post("/getUserFav").then((result) => {
            return result.data.response;
        });
    }

    setUserFav(product_id) {
        return http.post("/setUserFav", {product_id}).then((result) => {
            return result.data.response;
        });
    }

    deleteUserFav(product_id) {
        return http.post("/deleteUserFav", {product_id}).then((result) => {
            return result.data.response;
        });
    }


    updatePassword(data) {
        return http.post("/userUpdatePassword", data).then((result) => {
            return result.data.response;
        });
    }

    getCoupons() {
        return http.post("/getCouponList").then((result) => {
            return result.data;
        });
    }

    checkCouponCode(code) {
        return http.post("/checkCouponCode", {code}).then((result) => {
            return result.data.response;
        });
    }

    getPoints() {
        return http.post("/getPoints").then((result) => {

            return result.data;
        });
    }

    createCoupon() {
        return http.post("/createCoupon").then((result) => {
            return result.data;
        });
    }

    getBeautyProfile() {
        return http.post("/getBeautyProfile").then((result) => {
            return result.data;
        });
    }

    setBeautyProfile(data) {
        return http.post("/setBeautyProfile", {data: [...data]}).then((result) => {
            return result;
        });
    }

    getUserBeautyProfile() {
        return http.post("/userBeautyProfile").then((result) => {
            return result.data;
        });
    }

    passwordValidCode(data) {
        return http.post("/passwordValidCode", data).then((result) => {
            return result.data.response;
        });
    }

    getPreferences() {
        return http.post("/getPreferences").then((result) => {
            return result.data;
        });
    }

    savePreferences(data) {
        return http.post("/savePreferences", data).then((result) => {
            return result.data;
        });
    }

    getAskedQuestions(last_id) {
        return http.post("/getQuestions", {last_id}).then((result) => {
            return result.data.response;
        });
    }

    getContract() {
        return http.get("/contents/18").then((result) => {
            return result.data;
        });
    }




    getVerify(type){
        return http.post("/getVerify",{type,function:'getVerifyCode'}).then((result) => {
            return result.data;
        });
    }
    setVerify(data){
        return http.post("/getVerify",{...data,function:'setVerifyCode'}).then((result) => {
            return result.data;
        });
    }
}

export default new UserService();
