import http from "../utils/HttpCommon";

class CartService {
    getCart(mini,products=[]) {
        return http.post("/cart", {mini,products}, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data.response;
        });
    }

    addToCart(urun_no, adet, mini,) {
        return http.post("/addToCart", {urun_no, adet, mini}, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data.response;
        });
    }

    removeFromCart(urun_no) {
        return http.post("/deleteToCart", {urun_no}, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data.response;
        });
    }

    addToFullCart(data) {
        return http.post("/addToFullCart", {data}, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data.response;
        });
    }

    updateCart(urun_no, adet) {
        return http.post("/updateCartItem", {data: {urun_no, adet}}, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data.response;
        });
    }

    addCouponToBasket(data) {
        return http.post("/addCoupon", data, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data;
        });
    }

    removeCouponFromBasket(coupon) {
        return http.post("/deleteCoupon", coupon, {
            baseURL: 'https://api.misshaturkiye.net',
            proxy: {
                host: 'http://localhost',
                port: 3000,
                protocol: 'https'
            }
        }).then((result) => {
            return result.data;
        });
    }
}

export default new CartService();