import {orderConstants} from "../constants";

const initialState = {orders: null, statusCodes: null, storeOrders: null};

export default function orderReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case orderConstants.GET_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                storeOrders: payload
            };
        case orderConstants.GET_STORE_ORDERS_FAIL:
            return {
                ...state,
                storeOrders: null
            };
        case orderConstants.GET_STORE_ORDER_DETAILS_SUCCESS:
            const storeOrderList = [...state.storeOrders];
            const storeOrderIndex = storeOrderList.findIndex(o => o.sp_id === payload.order_id);
            if (storeOrderList[storeOrderIndex]) {
                storeOrderList[storeOrderIndex].details = {
                    sales: payload.result.satislar,
                    returns: payload.result.iadeler,
                    cargo: payload.result.kargo
                };
                return {
                    ...state,
                    storeOrders: storeOrderList,
                };
            } else {
                return {
                    ...state
                };
            }
        case orderConstants.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.siparisler,
                statusCodes: payload.durumKodlari
            };
        case orderConstants.GET_ORDERS_FAIL:
            return {
                ...state,
                orders: null,
                statusCodes: null
            };
        case orderConstants.GET_ORDER_DETAILS_SUCCESS:
            const orderList = [...state.orders];
            const orderIndex = orderList.findIndex(o => o.sp_id === payload.order_id);
            if (orderList[orderIndex]) {
                orderList[orderIndex].details = {
                    sales: payload.result.satislar,
                    returns: payload.result.iadeler,
                    cargo: payload.result.kargo,
                    sp_odemeTipi: payload.result.sp_odemeTipi
                };
                return {
                    ...state,
                    orders: orderList,
                };
            } else {
                return {
                    ...state
                };
            }
        default:
            return state;
    }
}