import HomeService from "../../services/home.service";
import {homeConstants} from "../constants";


export const getHome = () => (dispatch) => {
    return HomeService.getHomePage().then(
        (result) => {
            dispatch({
                type: homeConstants.GET_HOME_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {
            dispatch({
                type: homeConstants.GET_HOME_FAIL,
            });

            return Promise.reject();
        }
    );
};