export const menuConstants = {
    //Main
    GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
    GET_MENU_FAIL: 'GET_MENU_FAIL',
    //Middle
    TOGGLE_MIDDLE_MENU: 'TOOGLE_MIDDLE_MENU',
    //Mobile Footer
    SHOW_MOBILE_FOOTER: 'SHOW_MOBILE_FOOTER',
    //Mobile
    GET_MOBILE_MENU_SUCCESS: 'GET_MOBILE_MENU_SUCCESS',
    GET_MOBILE_MENU_FAIL: 'GET_MOBILE_MENU_FAIL',
    SET_SELECTED_PAGE_MENU_TITLE: 'SET_SELECTED_PAGE_MENU_TITLE',
    //Profile
    SET_ACTIVE_PROFILE_TAB: 'SET_ACTIVE_PROFILE_TAB',
    //Profile
    GET_MENU_INIT_SUCCESS: 'GET_MENU_INIT_SUCCESS',
    GET_MENU_INIT_FAIL: 'GET_MENU_INIT_FAIL',
};


export const homeConstants = {
    GET_HOME_SUCCESS: 'GET_HOME_SUCCESS',
    GET_HOME_FAIL: 'GET_HOME_FAIL'
};

export const productConstants = {
    //
    GET_PRODUCT_INIT_SUCCESS: 'GET_PRODUCT_INIT_SUCCESS',
    GET_PRODUCT_INIT_FAIL: 'GET_PRODUCT_INIT_FAIL',
    //Commets
    GET_PRODUCT_COMMENTS_SUCCESS: 'GET_PRODUCT_COMMENTS_SUCCESS',
    GET_PRODUCT_COMMENTS_FAIL: 'GET_PRODUCT_COMMENTS_FAIL',
    INSERT_COMMENT_SUCCESS: 'INSERT_COMMENT_SUCCESS',
    INSERT_COMMENT_FAIL: 'INSERT_COMMENT_FAIL',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAIL: 'UPDATE_COMMENT_FAIL',
    GET_WAITING_COMMENTS_SUCCESS: 'GET_WAITING_COMMENTS_SUCCESS',
    GET_WAITING_COMMENTS_FAIL: 'GET_WAITING_COMMENTS_FAIL',
    GET_OLD_COMMENTS_SUCCESS: 'GET_OLD_COMMENTS_SUCCESS',
    GET_OLD_COMMENTS_FAIL: 'GET_OLD_COMMENTS_FAIL',
    CLEAR_PRODUCT_SUBSECTION: 'CLEAR_PRODUCT_SUBSECTION',
    //Questions
    GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
    GET_QUESTIONS_FAIL: 'GET_QUESTIONS_FAIL',
    ASK_QUESTION_SUCCESS: 'INSERT_QUESTION_SUCCESS',
    ASK_QUESTION_FAIL: 'INSERT_QUESTION_FAIL',
    //Opporunities
    GET_OPPORTUNITIES_SUCCESS: 'GET_OPPORTUNITIES_SUCCESS',
    GET_OPPORTUNITIES_FAIL: 'GET_OPPORTUNITIES_FAIL',
    //Last Visited Products
    ADD_TO_LAST_VISITED_PRODUCTS: 'ADD_TO_LAST_VISITED_PRODUCTS',
    //Wait Product
    GET_WAIT_PRODUCTS_SUCCESS: 'GET_WAIT_PRODUCTS_SUCCESS',
    GET_WAIT_PRODUCTS_FAIL: 'GET_WAIT_PRODUCTS_FAIL',
    SET_WAIT_PRODUCT_SUCCESS: 'SET_WAIT_PRODUCT_SUCCESS',
    SET_WAIT_PRODUCT_FAIL: 'SET_WAIT_PRODUCT_FAIL',
    DELETE_WAIT_PRODUCT_SUCCESS: 'DELETE_WAIT_PRODUCT_SUCCESS',
    DELETE_WAIT_PRODUCT_FAIL: 'DELETE_WAIT_PRODUCT_FAIL',
    //Cargo
    GET_CARGO_SUCCESS: 'GET_CARGO_SUCCESS',
    GET_CARGO_FAIL: 'GET_CARGO_FAIL',
    //Detail
    GET_PRODUCT_DETAIL_SUCCESS: 'GET_PRODUCT_DETAIL_SUCCESS',
    GET_PRODUCT_DETAIL_FAIL: 'GET_PRODUCT_DETAIL_FAIL',
    //Opportunity Products
    SET_OPPORTUNITY_PRODUCTS: 'SET_OPPORTUNITY_PRODUCTS',
    SET_QUICKLOOKMODAL_DATA: 'SET_QUICKLOOKMODAL_DATA'
};

export const authConstants = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'USERS_LOGOUT',
};

export const registrationConstants = {
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL'
};

export const userConstants = {
    //Profile
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAIL: 'GET_USER_PROFILE_FAIL',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_FAIL: 'UPDATE_USER_PROFILE_FAIL',
    //Password
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL',
    PASSWORD_VALID_CODE_SUCCESS: 'PASSWORD_VALID_CODE_SUCCESS',
    PASSWORD_VALID_CODE_FAIL: 'PASSWORD_VALID_CODE_FAIL',
    //Favorites
    GET_ALL_FAVORITES_SUCCESS: 'GET_ALL_FAVORITES_SUCCESS',
    GET_ALL_FAVORITES_FAIL: 'GET_ALL_FAVORITES_FAIL',
    SET_FAVORITE_SUCCESS: 'SET_FAVORITE_SUCCESS',
    SET_FAVORITE_FAIL: 'SET_FAVORITE_FAIL',
    DELETE_FAVORITE_SUCCESS: 'DELETE_FAVORITE_SUCCESS',
    DELETE_FAVORITE_FAIL: 'DELETE_FAVORITE_FAIL',
    //Coupon
    GET_COUPONS_SUCCESS: 'GET_COUPONS_SUCCESS',
    GET_COUPONS_FAIL: 'GET_COUPONS_FAIL',
    CHECK_COUPON_CODE_SUCCESS: 'CHECK_COUPON_CODE_SUCCESS',
    CHECK_COUPON_CODE_FAIL: 'CHECK_COUPON_CODE_FAIL',
    //Points
    GET_POINTS_SUCCESS: 'GET_POINTS_SUCCESS',
    GET_POINTS_FAIL: 'GET_POINTS_FAIL',
    //Coupon
    CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
    CREATE_COUPON_FAIL: 'CREATE_COUPON_FAIL',
    //Beauty Profile
    GET_BEAUTY_PROFILE_SUCCESS: 'GET_BEAUTY_PROFILE_SUCCESS',
    GET_BEAUTY_PROFILE_FAIL: 'GET_BEAUTY_PROFILE_FAIL',
    SET_BEAUTY_PROFILE_SUCCESS: 'SET_BEAUTY_PROFILE_SUCCESS',
    SET_BEAUTY_PROFILE_FAIL: 'SET_BEAUTY_PROFILE_FAIL',
    GET_USER_BEAUTY_PROFILE_SUCCESS: 'GET_USER_BEAUTY_PROFILE_SUCCESS',
    GET_USER_BEAUTY_PROFILE_FAIL: 'GET_USER_BEAUTY_PROFILE_FAIL',
    //Preferences
    GET_PREFERENCES_SUCCESS: 'GET_PREFERENCES_SUCCESS',
    GET_PREFERENCES_FAIL: 'GET_PREFERENCES_FAIL',
    SAVE_PREFERENCES_SUCCESS: 'SAVE_PREFERENCES_SUCCESS',
    SAVE_PREFERENCES_FAIL: 'SAVE_PREFERENCES_FAIL',
    //Questions
    GET_ASKED_QUESTIONS_SUCCESS: 'GET_ASKED_QUESTIONS_SUCCESS',
    GET_ASKED_QUESTIONS_FAIL: 'GET_ASKED_QUESTIONS_FAIL',
    //Questions
    GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
    GET_CONTRACT_FAIL: 'GET_CONTRACT_FAIL',
};

export const orderConstants = {
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',
    //Store Orders
    GET_STORE_ORDERS_SUCCESS: 'GET_STORE_ORDERS_SUCCESS',
    GET_STORE_ORDERS_FAIL: 'GET_STORE_ORDERS_FAIL',
    GET_STORE_ORDER_DETAILS_SUCCESS: 'GET_STORE_ORDER_DETAILS_SUCCESS',
    //Order details
    GET_ORDER_DETAILS_SUCCESS: 'GET_ORDER_DETAILS_SUCCESS',
    GET_ORDER_DETAILS_FAIL: 'GET_ORDER_DETAILS_FAIL',
    //Cancel
    CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAIL: 'CANCEL_ORDER_FAIL',
    //Giving Back
    GIVING_BACK_SUCCESS: 'GIVING_BACK_SUCCESS',
    GIVING_BACK_FAIL: 'GIVING_BACK_FAIL',
};

export const returnConstants = {
    //Returns
    GET_RETURNS_SUCCESS: 'GET_RETURN_SUCCESS',
    GET_RETURNS_FAIL: 'GET_RETURN_FAIL',
    GET_RETURN_DETAILS_SUCCESS: 'GET_RETURN_DETAILS_SUCCESS',
    GET_RETURN_DETAILS_FAIL: 'GET_RETURN_DETAILS_FAIL',
    //Requests
    GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
    GET_REQUESTS_FAIL: 'GET_REQUESTS_FAIL',
};

export const storeConstants = {
    GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
    GET_STORES_FAIL: 'GET_STORES_FAIL',
};

export const cartConstants = {
    GET_CART: 'GET_CART',
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    CHANGE_QUANTITY: 'CHANGE_QUANTITY',
    DELETE_CART: 'DELETE_CART'
}

export const locationConstants = {
    //Address
    GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
    GET_ADDRESS_FAIL: 'GET_ADDRESS_FAIL',
    DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
    DELETE_ADDRESS_FAIL: 'DELETE_ADDRESS_FAIL',
    SET_DEFAULT_ADDRESS_SUCCESS: 'SET_DEFAULT_ADDRESS_SUCCESS',
    SET_DEFAULT_ADDRESS_FAIL: 'SET_DEFAULT_ADDRESS_FAIL',
    //City
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_FAIL: 'GET_CITIES_FAIL',
    //County
    GET_COUNTIES_SUCCESS: 'GET_COUNTIES_SUCCESS',
    GET_COUNTIES_FAIL: 'GET_COUNTIES_FAIL',
}

export const paymentConstants = {
    //Cards
    GET_CARDS_SUCCESS: 'GET_CARDS_SUCCESS',
    GET_CARDS_FAIL: 'GET_CARDS_FAIL',
    DELETE_CARD_SUCCESS: 'DELETE_CARD_SUCCESS',
    DELETE_CARD_FAIL: 'DELETE_CARD_FAIL',
    //Bank
    GET_BANK_EFT_SUCCESS: 'GET_BANK_EFT_SUCCESS',
    GET_BANK_EFT_FAIL: 'GET_BANK_EFT_FAIL',
    //Wallet
    CHECK_WALLET_SUCCESS: 'CHECK_WALLET_SUCCESS',
    CHECK_WALLET_FAIL: 'CHECK_WALLET_FAIL',
    //Transfer
    TRANSFER_REQUEST_SUCCESS: 'TRANSFER_REQUEST_SUCCESS',
    TRANSFER_REQUEST_FAIL: 'TRANSFER_REQUEST_FAIL',
    GET_TRANSFER_REQUEST_SUCCESS: 'GET_TRANSFER_REQUEST_SUCCESS',
    GET_TRANSFER_REQUEST_FAIL: 'GET_TRANSFER_REQUEST_FAIL',
    CANCEL_TRANSFER_REQUEST_SUCCESS: 'CANCEL_TRANSFER_REQUEST_SUCCESS',
    CANCEL_TRANSFER_REQUEST_FAIL: 'CANCEL_TRANSFER_REQUEST_FAIL',
};

export const socialConstants = {
    //Youtube
    GET_YOUTUBE_SUCCESS: 'GET_YOUTUBE_SUCCESS',
    GET_YOUTUBE_FAIL: 'GET_YOUTUBE_FAIL',
    SET_SELECTED_YOUTUBE_VIDEO: 'SET_SELECTED_YOUTUBE_VIDEO',
    //Instagram
    GET_INSTAGRAM_SUCCESS: 'GET_INSTAGRAM_SUCCESS',
    GET_INSTAGRAM_FAIL: 'GET_INSTAGRAM_FAIL',
    SET_SELECTED_INSTAGRAM: 'SET_SELECTED_INSTAGRAM',
    //Blog
    GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
    GET_BLOG_FAIL: 'GET_BLOG_FAIL'
};

export const footerConstants = {
    GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
    GET_PAGE_FAIL: 'GET_PAGE_FAIL',
};





