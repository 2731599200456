import LocationService from "../../services/location.service";
import {locationConstants} from "../constants";
import {alert} from "../../shared/Alert/Alert";

export const getAddresses = () => (dispatch) => {
    return LocationService.getAddresses().then(
        (result) => {
            dispatch({
                type: locationConstants.GET_ADDRESS_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {
            dispatch({
                type: locationConstants.GET_ADDRESS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const addAddress = (address) => (dispatch) => {
    return LocationService.addAddress(address).then(
        (result) => {
            dispatch(getAddresses());

            alert("success", "Adresiniz başarıyla eklenmiştir!")

            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
};

export const updateAddress = (address) => (dispatch) => {
    return LocationService.updateAddress(address).then(
        (result) => {
            dispatch(getAddresses());

            alert("success", "Adresiniz başarıyla güncellenmiştir!")

            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
};

export const deleteAddress = (address_no) => (dispatch) => {
    return LocationService.deleteAddress(address_no).then(
        (result) => {
            dispatch({
                type: locationConstants.DELETE_ADDRESS_SUCCESS,
                payload: address_no
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: locationConstants.DELETE_ADDRESS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setDefaultAddress = (address_no) => (dispatch) => {
    return LocationService.setDefaultAddress(address_no).then(
        (result) => {
            dispatch({
                type: locationConstants.SET_DEFAULT_ADDRESS_SUCCESS,
                payload: address_no
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: locationConstants.SET_DEFAULT_ADDRESS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getCities = () => (dispatch) => {
    return LocationService.getCities().then(
        (result) => {
            dispatch({
                type: locationConstants.GET_CITIES_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: locationConstants.GET_CITIES_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getCounties = (city_id) => (dispatch) => {
    return LocationService.getCounties(city_id).then(
        (result) => {
            dispatch({
                type: locationConstants.GET_COUNTIES_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: locationConstants.GET_COUNTIES_FAIL,
            });

            return Promise.reject();
        }
    );
};
