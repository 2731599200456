import {returnConstants} from "../constants";

const initialState = {returns: null, requests: null};

export default function returnReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case returnConstants.GET_RETURNS_SUCCESS:
            return {
                ...state,
                returns: payload
            };
        case returnConstants.GET_RETURNS_FAIL:
            return {
                ...state,
                returns: null
            };
        case returnConstants.GET_RETURN_DETAILS_SUCCESS:
            const returnList = [...state.returns.iadeList];
            const returnIndex = returnList.findIndex(o => o.iade_id === payload.iade_id);
            returnList[returnIndex].details = payload.result;
            return {
                ...state,
                returns: {...state.returns, iadeList: returnList},
            };
        case returnConstants.GET_RETURN_DETAILS_FAIL:
            return {
                ...state
            };
        case returnConstants.GET_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: payload
            };
        case returnConstants.GET_REQUESTS_FAIL:
            return {
                ...state,
                requests: null
            };
        default:
            return state;
    }
}