import http from "../utils/HttpCommon";

class MenuService {
    getMenus() {
        return http.post("/menus").then((result) => {
            return result.data.response;
        });
    }

    getMobileMenu() {
        return http.post("/mobilmenu").then((result) => {
            return result.data.response;
        });
    }

    getMenuInit(menuId) {
         return  http.get(`/menuDetay?menuID=${menuId}`).then((result) => {
            return result.data.response;
        });
    }
}

export default new MenuService();