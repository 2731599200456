import {paymentConstants} from "../constants";
import _ from "underscore";

const currentYear = new Date().getUTCFullYear();

const initialState = {
    cards: [],
    eftBanks: [],
    wallet: {},
    months: [
        {id: '01', name: 'Ocak'},
        {id: '02', name: 'Şubat'},
        {id: '03', name: 'Mart'},
        {id: '04', name: 'Nisan'},
        {id: '05', name: 'Mayıs'},
        {id: '06', name: 'Haziran'},
        {id: '07', name: 'Temmuz'},
        {id: '08', name: 'Ağustos'},
        {id: '09', name: 'Eylül'},
        {id: '10', name: 'Ekim'},
        {id: '11', name: 'Kasım'},
        {id: '12', name: 'Aralık'}
    ],
    years: _.range(currentYear, currentYear + 9)
};

export default function paymentReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case paymentConstants.GET_TRANSFER_REQUEST_SUCCESS:
            return {
                ...state,
                transferRequests: payload,
            };
        case paymentConstants.GET_TRANSFER_REQUEST_FAIL:
            return {
                ...state,
                transferRequests: {}
            };
            case paymentConstants.GET_CARDS_SUCCESS:
            return {
                ...state,
                cards: payload
            };
        case paymentConstants.GET_CARDS_FAIL:
            return {
                ...state,
                cards: []
            };
        case paymentConstants.DELETE_CARD_SUCCESS:
            const newCards = state.cards.filter(item => item.cardId !== payload.kartId);
            return {
                ...state,
                cards: newCards
            };
        case paymentConstants.GET_BANK_EFT_SUCCESS:
            return {
                ...state,
                eftBanks: payload
            };
        case paymentConstants.GET_BANK_EFT_FAIL:
            return {
                ...state,
                eftBanks: null
            };
        case paymentConstants.CHECK_WALLET_SUCCESS:
            return {
                ...state,
                wallet: payload
            };
        case paymentConstants.CHECK_WALLET_FAIL:
            return {
                ...state,
                wallet: null
            };
        case paymentConstants.TRANSFER_REQUEST_SUCCESS:
            return {
                ...state
            };
        case paymentConstants.TRANSFER_REQUEST_FAIL:
            return {
                ...state
            };
        case paymentConstants.CANCEL_TRANSFER_REQUEST_SUCCESS:
            return {
                ...state
            };
        case paymentConstants.CANCEL_TRANSFER_REQUEST_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}