const loader = document.querySelector('.lds-ellipsis');

const overlay = document.getElementById('overlay')

export const showLoader = () => {
    overlay.style.display = 'block'
    loader.classList.remove('loader--hide');
}

export const hideLoader = () => {
    overlay.style.display = 'none'
    loader.classList.add('loader--hide');
}