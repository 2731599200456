import {authConstants} from "../constants";

const user = JSON.parse(localStorage.getItem("misshaUser"));

const initialState = user?.token ? {isLoggedIn: true, user} : {isLoggedIn: false, user: null};

export default function authReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.data,
            };
        case authConstants.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}