import {registrationConstants} from "../constants";

const initialState = {};

export default function registrationReducer(state = initialState, action) {
    const {type} = action;

    switch (type) {
        case registrationConstants.REGISTER_SUCCESS:
            return {
                ...state
            };
        case registrationConstants.REGISTER_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}