import "./App.scss";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { MINIMUM_WINDOW_WIDTH } from "./constants/Constants";
import { getOpportunityProducts, getProductInit } from "./store/actions/product.actions";
import { BasketDeleteConfirmModal } from "./shared/BasketDeleteConfirmModal/BasketDeleteConfirmModal";
import { getCities } from "./store/actions/location.actions";
import { getHome } from "./store/actions/home.actions";
import { getMenu } from "./store/actions/menu.actions";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { userFavorites } from "./store/actions/user.actions";
import useWindowDimensions from "./utils/UseWindowDimensions";
import OpportunityModal from "./shared/OpportunityModal/OpportunityModal";
import { PaymentModal } from "./shared/PaymentModal/PaymentModal";
import QuickLookModal from "./shared/QuickLookModal/QuickLookModal";
import { CardSaveModal } from "./shared/CardSaveModal/CardSaveModal";

const Enes = React.lazy(() => import("./pages/Enes/Enes").then(({ default: Enes }) => ({ default: Enes })));
const Login = React.lazy(() => import("./pages/Login/Login").then(({ default: Login }) => ({ default: Login })));
const Header = React.lazy(() => import("./layout/Header/Header").then(({ default: Header }) => ({ default: Header })));
const AwardedProducts = React.lazy(() =>
  import("./pages/AwardedProducts/AwardedProducts").then(({ default: AwardedProducts }) => ({ default: AwardedProducts }))
);
const Campaigns = React.lazy(() => import("./pages/Campaigns/Campaigns").then(({ default: Campaigns }) => ({ default: Campaigns })));
const ProductDetail = React.lazy(() =>
  import("./pages/ProductDetail/ProductDetail").then(({ default: ProductDetail }) => ({ default: ProductDetail }))
);
const Profile = React.lazy(() => import("./pages/Profile/Profile").then(({ default: Profile }) => ({ default: Profile })));
const Basket = React.lazy(() => import("./pages/Basket/Web/Basket").then(({ default: Basket }) => ({ default: Basket })));
const SearchResult = React.lazy(() => import("./pages/SearchResult/SearchResult").then(({ default: SearchResult }) => ({ default: SearchResult })));
const FooterCommonPage = React.lazy(() =>
  import("./pages/FooterCommonPage/FooterCommonPage").then(({ default: FooterCommonPage }) => ({ default: FooterCommonPage }))
);
const PaymentConfirm = React.lazy(() =>
  import("./pages/PaymentConfirm/PaymentConfirm").then(({ default: PaymentConfirm }) => ({ default: PaymentConfirm }))
);
const ProductList = React.lazy(() => import("./pages/ProductList/ProductList").then(({ default: ProductList }) => ({ default: ProductList })));
const MobileHeader = React.lazy(() => import("./layout/MobileHeader/MobileHeader").then(({ default: MobileHeader }) => ({ default: MobileHeader })));
const Footer = React.lazy(() => import("./layout/Footer/Footer").then(({ default: Footer }) => ({ default: Footer })));
const MobileFooter = React.lazy(() => import("./layout/MobileFooter/MobileFooter").then(({ default: MobileFooter }) => ({ default: MobileFooter })));


const OurBrands = React.lazy(() => import("./pages/OurBrands/OurBrands").then(({ default: OurBrands }) => ({ default: OurBrands })));

const MobileBasket = React.lazy(() => import("./pages/Basket/Mobile/MobileBasket").then(({ default: MobileBasket }) => ({ default: MobileBasket })));
const MobileBasketTestersSection = React.lazy(() =>
  import("./pages/Basket/Mobile/MobileBasketTestersSection/MobileBasketTestersSection").then(({ default: MobileBasketTestersSection }) => ({
    default: MobileBasketTestersSection,
  }))
);
const MobileBasketConfirmSection = React.lazy(() =>
  import("./pages/Basket/Mobile/MobileBasketConfirmSection/MobileBasketConfirmSection").then(({ default: MobileBasketConfirmSection }) => ({
    default: MobileBasketConfirmSection,
  }))
);

const BlackHeader = React.lazy(() => import("./layout/BlackHeader/BlackHeader").then(({ default: BlackHeader }) => ({ default: BlackHeader })));
const NewProductsWeb = React.lazy(() =>
  import("./pages/NewProducts/NewProductsWeb/NewProductsWeb").then(({ default: NewProductsWeb }) => ({ default: NewProductsWeb }))
);

const NewProductsMobile = React.lazy(() =>
  import("./pages/NewProducts/NewProductsMobile/NewProductsMobile").then(({ default: NewProductsMobile }) => ({ default: NewProductsMobile }))
);

const TestersWeb = React.lazy(() => import("./pages/Testers/TestersWeb/TestersWeb").then(({ default: TestersWeb }) => ({ default: TestersWeb })));
const TestersMobile = React.lazy(() =>
  import("./pages/Testers/TestersMobile/TestersMobile").then(({ default: TestersMobile }) => ({ default: TestersMobile }))
);
const CampaignProducts = React.lazy(() =>
  import("./pages/Campaigns/CampaignProducts/CampaignProducts").then(({ default: CampaignProducts }) => ({ default: CampaignProducts }))
);
const OpportunityGroups = React.lazy(() =>
  import("./pages/Campaigns/OpportunityGroups/OpportunityGroups").then(({ default: OpportunityGroups }) => ({ default: OpportunityGroups }))
);
const Offers = React.lazy(() => import("./pages/Campaigns/Offers/Offers").then(({ default: Offers }) => ({ default: Offers })));
const Home = React.lazy(() => import("./pages/Home/Home").then(({ default: Home }) => ({ default: Home })));

const App = ({ userFavorites, isLoggedIn, cartItems, getHome, getCities, getMenu, getProductInit, getOpportunityProducts, ...rest }) => {


  const { width } = useWindowDimensions();
  const isMobile = width<MINIMUM_WINDOW_WIDTH?true:false;

  const location = useLocation();

  useEffect(() => {
    getHome();
    getProductInit();
    getMenu(isLoggedIn);
    getCities();
    getOpportunityProducts();

    if (isLoggedIn) {
      userFavorites();
    }
  }, [getProductInit, getMenu, isLoggedIn, userFavorites]);

  useEffect(() => {
    const mobileIndex = document.getElementById("root");
    if (isMobile) {
      mobileIndex.classList.add("mobileIndex");
    } else {
      mobileIndex.classList.remove("mobileIndex");
    }
  }, [width]);


  let numberOfCartItems = 0;
  cartItems?.forEach((obj) => {
    numberOfCartItems += obj.adet;
  });

  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        window?.closeAllModals();
      }
    });
  }, []);

  return (
    <div className="app">
      <React.Suspense
        fallback={
          <div className="loader">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        }
      >
        <Route
          path="/"
          render={(props) => {
            if ((location.pathname === "/sepetim" && !isMobile) || location.pathname === "/vitrin") {
              return <BlackHeader {...props} />;
            } else if (location.pathname !== "/giris" && !isMobile) {
              return <Header {...props} numberOfCartItems={numberOfCartItems} />;
            } else if (location.pathname !== "/giris" && isMobile) {
              return <MobileHeader {...props} numberOfCartItems={numberOfCartItems} />;
            }
          }}
        />


          <Route exact path="/">
            <Redirect to="/anasayfa" />
          </Route>

          <Route exact path="/enes"  render={(props)=>{return <Enes {...props} isMobile={isMobile} />}}  />
          <Route exact path="/anasayfa" render={(props)=>{return <Home {...props} isMobile={isMobile} />}}  />
          <Route exact path="/giris" render={(props)=>{return <Login {...props} isMobile={isMobile} />}} />
          <Route exact path="/odullu-urunler/:id" render={(props)=>{return <AwardedProducts {...props} isMobile={isMobile} />}} />
          <Route
            exact
            path="/yeni-urunler/:id"
            render={(props) => {
              if (!isMobile) {
                return <NewProductsWeb {...props} filterId={props.match.params.id} isMobile={isMobile} />;
              } else {
                return <NewProductsMobile {...props} filterId={props.match.params.id} isMobile={isMobile} />;
              }
            }}
          />

          <Route
            path="/sepetim"
            render={(props) => {
              if (isMobile) {
                return <MobileBasket {...props} isMobile={isMobile} />;
              } else {
                return <Basket {...props}  isMobile={isMobile}/>;
              }
            }}
          />
          <Route exact path="/tester-secim" render={(props)=>{return <MobileBasketTestersSection {...props} isMobile={isMobile} />}} />
          <Route exact path="/markalar"  render={(props)=>{return <OurBrands {...props} isMobile={isMobile} />}} />
          <Route exact path="/sepet-odeme-onay"  render={(props)=>{return <MobileBasketConfirmSection {...props} isMobile={isMobile} />}} />

          <Route
            path="/kampanyalar"
            render={({ match: { url } }) => (
              <>
                <Route exact path={`${url}/`}  render={(props)=>{return <Campaigns {...props} isMobile={isMobile} />}} />
                <Route exact path={`${url}/teklifler`}  render={(props)=>{return <Offers {...props} isMobile={isMobile} />}} />
                <Route exact path={`${url}/fırsat-grupları/:kmp_id`} render={(props)=>{return <OpportunityGroups {...props} isMobile={isMobile} />}} />
                <Route exact path={`${url}/urunler/:kmp_id`} render={(props)=>{return <CampaignProducts {...props} isMobile={isMobile} />}} />
              </>
            )}
          />
          <Route exact path="/urun-detay/:name/:id" render={(props)=>{return <ProductDetail {...props} isMobile={isMobile} />}} />

        <Route exact path="/urun-listesi/*"  render={(props)=>{return <ProductList {...props} isMobile={isMobile} />}} />

          <Route exact path="/odeme-onay"  render={(props)=>{return <PaymentConfirm {...props} isMobile={isMobile} />}} />
          <Route exact path="/arama-sonucu/:key"  render={(props)=>{return <SearchResult {...props} isMobile={isMobile} />}} />
          <Route
            exact
            path="/tester/:id"
            render={(props) => {
              if (!isMobile) {
                return <TestersWeb {...props} filterId={props.match.params.id} isMobile={isMobile} />;
              } else {
                return <TestersMobile {...props} filterId={props.match.params.id} isMobile={isMobile} />;
              }
            }}
          />

          <Route exact path="/profil"  render={(props)=>{return <Profile {...props} isMobile={isMobile} />}} />

          <Route exact path="/missclub/:name/:id"  render={(props)=>{return <FooterCommonPage {...props} isMobile={isMobile} />}} />

        <Route
          path="/"
          render={(props) => {
            const paths = ["/giris", "/sepetim", "/tester-secim", "/sepet-odeme-onay", "/odeme-onay"];
            if (!paths.includes(location.pathname) && !isMobile) {
              return <Footer isMobile={isMobile} />;
            } else if (!paths.includes(location.pathname) && isMobile) {
              return <MobileFooter isMobile={isMobile} />;
            }
          }}
        />
      </React.Suspense>
      <OpportunityModal isMobile={isMobile} />
      <PaymentModal isMobile={isMobile} />
      <QuickLookModal isMobile={isMobile} />
      <CardSaveModal isMobile={isMobile} />

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cdn: state.menu?.mainConstants?.cdn,
    favorites: state.user?.favorites,
    products: state.product.products,
    cartItems: state.cart.cart?.urunler,
    isLoggedIn: state.authentication.isLoggedIn,
    mainMenu: state.menu?.mainMenu?.anaMenu,
    productFilters: state.product.productFilters,
    lastVisitedProducts: state.product?.lastVisitedProducts,
    homeData: state.home?.data,
  };
};

const mapDispatchToProps = {
  getProductInit,
  getOpportunityProducts,
  getMenu,
  getHome,
  getCities,
  userFavorites,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
