const Stars = ({n, classes}) => {
    let stars = "";
    for (let i = 0; i < Math.floor(n); i++) {
        stars += `<i class='ri-star-fill ${classes ? classes : ''} '/>,`
    }

    if (n % 1 !== 0) {
        stars += `<i class='ri-star-half-line ${classes ? classes : ''} '/>,`
    }

    for (let i = 0; i < Math.floor(5 - n); i++) {
        if (i + 1 === Math.floor(5 - n)) {
            stars += `<i class='ri-star-line ${classes ? classes : ''} '/>`
        } else {
            stars += `<i class='ri-star-line ${classes ? classes : ''} '/>,`
        }
    }
    return (
        <>
            {stars.split(',').map((item, key) => {
                return (
                    <span className="d-inline-flex align-items-center" key={key} dangerouslySetInnerHTML={{__html: item}}/>
                )
            })}
        </>
    )
}

export default Stars