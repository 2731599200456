import {storeConstants} from "../constants";

const initialState = {stores: null};

export default function storeReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case storeConstants.GET_STORES_SUCCESS:
            return {
                ...state,
                stores: payload
            };
        case storeConstants.GET_STORES_FAIL:
            return {
                ...state,
                stores: null
            };
        default:
            return state;
    }
}