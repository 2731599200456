import axios from "axios";
import {history} from "./history";


const instance = axios.create({
    headers: {
      //  "Content-type": "application/x-www-form-urlencoded"
        "Content-type": "application/json"

    }});


instance.interceptors.request.use(
    config => {
        const nonAuthUrls = [
            'https://misshaturkiye.net/payment/getCards.php',
            'https://misshaturkiye.net/payment/deleteCard.php',
            'https://misshaturkiye.net/payment/cardQuery.php',
            'https://misshaturkiye.net/payment/saveCard.php',
        ]

        if(config.url==='/insertComment'){
            config.headers = {
                  "Content-type": "application/x-www-form-urlencoded"
            }
        }

       config.baseURL = 'https://api.misshaturkiye.net';

        if (!config.headers.Authorization && !nonAuthUrls.includes(config.url)) {
            const token = JSON.parse(localStorage.getItem("misshaUser"))?.token;

            if (token) {
                config.withCredentials=true;
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },

    error => Promise.reject(error)
);




instance.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
      //  history.push("/giris");
      //  window.location.reload();
    }
    throw err;
})

export default instance;
