export const BASIC_INGREDIENT_ID = 13;
export const PRODUCT_FUNCTION_ID = 16;
export const SKIN_TYPE_ID = 3;
export const TESTER_ID = 448;
export const BB_CREAM_ID = 647;
export const DEFAULT_ADDRESS_FLAG = 1;
export const PROGRESS_MAX_VALUE = 100;
export const MINIMUM_WINDOW_WIDTH = 1024;
export const TIMER_VALUE = 3000;
export const OUT_OF_STOCK_VALUE = 3;
export const NEW_PRODUCTS_FILTER_ID = 446;
//Payments
export const CREDIT_CARD = "KK";
export const DOOR_PAYMENT = "KO";
export const EFT = "EFT";
export const WALLET_PAYMENT = "CZ";

export const PAYMENTS = [
    {key: "KK", text: "Kredi Kartı"},
    {key: "KO", text: "Kapıda Ödeme"},
    {key: "EFT", text: "EFT"},
    {key: "CZ", text: "Cüzdan"}
]
export const PAYMENTSOBJ = {
    'KK':'Kredi Kartı',
    'KO':'Kapıda Ödeme',
    'EFT':'Havale EFT',
    'CZ':'Cüzdan',
}

//Filter types
export const PRODUCT = "product";
export const FILTER = "filter";
export const MENU = "menu";
export const CAMPAIGN = "campaign";
export const CUSTOM = "custom";

//User Statuses
export const VIP = 1;

//Orders
export const TAKEN = 'ALINDI';
export const BILL_INVOICED = 'FATURA_KESILDI';
export const CANCELED = 'IPTAL_EDILDI';
export const CARGO_DELIVERED = 'KARGOYA_VERILDI';
export const APPROVED = 'ONAYLANDI';
export const DELIVERED = 'TESLIM_EDILDI';

//Footer Constans
export const INTERNAL = 1;