import {homeConstants} from "../constants";

const initialState = {data: null};

export default function homeReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case homeConstants.GET_HOME_SUCCESS:
            return {
                ...state,
                data: payload
            };
        case homeConstants.GET_HOME_FAIL:
            return {
                ...state,
                data: null
            };
        default:
            return state;
    }
}