import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
export const confirm_alert = (icon=null,title='Uyarı',message='',buttonLabel='Tamam',overlayClass='')=>{

    const options = {
        title: title,
        message: message,
        buttons: [
            {
                label: buttonLabel,
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {},
        overlayClassName: overlayClass
    };

    confirmAlert(options);
/*

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='modal nModal'>
                    <div className="modal-dialog">
                    <div className="modal-content">
                        {icon &&(<i>icon</i>)}
                        <h3>{title}</h3>
                        <p>{message}</p>
                        <button onClick={onClose}>Tamam</button>
                    </div>
                </div>

                </div>
            );
        }
    });
*/
}