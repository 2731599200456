import {cartConstants, productConstants} from "../constants";
import CartService from "../../services/cart.service";
import {alert} from "../../shared/Alert/Alert";
import {getOpportunityProducts} from "../../utils/OpportunityProducts";
import {hideLoader, showLoader} from "../../shared/Loader/Loader";


export const getCart = (products, mini = false) => (dispatch) => {
    return CartService.getCart(mini,products).then(
        (result) => {
            dispatch({
                type: cartConstants.GET_CART,
                payload: {
                    result,
                    products,
                    mini
                },
            });

            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
};



export const addToFullCart = (data, products) => (dispatch) => {
    return CartService.addToFullCart(data).then(
        (result) => {
            dispatch(getCart(products))
            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
}

export const addToCart = (product, products, numberOfProduct, isTester = false, isLoggedIn = false, campaigns = [], mini = true) => (dispatch) => {
    const adet = numberOfProduct ? numberOfProduct : 1;

    if (!isTester) {
        const data = getOpportunityProducts(product, products, campaigns);
        if(data){
            dispatch({
                type: productConstants.SET_OPPORTUNITY_PRODUCTS,
                payload: {...data},
            });

            window.openBasketOpportunityModal();
        }


    }

    if (isLoggedIn && !isTester) {
        return CartService.addToCart(product?._id, adet, mini).then(
            (result) => {
               // window.openModal("addSuccessModal");
                dispatch({
                    type: cartConstants.GET_CART,
                    payload: {
                        result,
                        products,
                        mini
                    },
                });
                return Promise.resolve();
            }, (error) => {
                return Promise.reject();
            }
        );
    }
    else {
        dispatch({
            type: cartConstants.ADD_TO_CART,
            payload: {
                product,
                adet,
                isTester
            }
        })



        return Promise.resolve();
    }
}

export const removeFromCart = (product, products, isTester = false, isLoggedIn = false) => (dispatch) => {
    if (isLoggedIn && !isTester) {
        return CartService.removeFromCart(product?._id).then(
            (result) => {
                dispatch({
                    type: cartConstants.GET_CART,
                    payload: {
                        result,
                        products,
                        mini: false
                    },
                });
                return Promise.resolve();
            }, (error) => {
                return Promise.reject();
            }
        );
    } else {
        dispatch({
            type: cartConstants.REMOVE_FROM_CART,
            payload: {
                product,
                isTester
            }
        });
    }
}


export const deleteCart = () => (dispatch) => {
    dispatch({
        type: cartConstants.DELETE_CART
    });
};

export const changeQuantity = (product, products, adet, isTester = false, isLoggedIn = false) => (dispatch) => {
    showLoader()
    if (isLoggedIn) {
        return CartService.updateCart(product?._id, adet).then(
            (result) => {
                dispatch({
                    type: cartConstants.GET_CART,
                    payload: {
                        result,
                        products,
                        mini: false
                    },
                });
                hideLoader()
                return Promise.resolve();
            }, (error) => {
                return Promise.reject();
            }
        );
    } else {
        dispatch({
            type: cartConstants.CHANGE_QUANTITY,
            payload: {
                product,
                adet,
                isTester,
                isLoggedIn
            }
        });
    }
}

export const addCouponToBasket = (data) => (dispatch) => {
    showLoader()
    return CartService.addCouponToBasket({coupon: data.coupon?._id, inputCoupon: data.inputCoupon}).then(
        (result) => {
            if (result.success) {
                dispatch({
                    type: cartConstants.GET_CART,
                    payload: {
                        result: result.response,
                        products: data.products,
                        mini: false
                    },
                });
            } else {
                alert("error", result.message)
            }
            hideLoader();
            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
}

export const removeCouponFromBasket = (products, coupon) => (dispatch) => {
    showLoader()
    return CartService.removeCouponFromBasket(coupon).then(
        (result) => {
            if (result.success) {
                dispatch({
                    type: cartConstants.GET_CART,
                    payload: {
                        result: result.response,
                        products,
                        mini: false
                    },
                });
            } else {
                alert("error", result.message)
            }
            hideLoader();
            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
}
