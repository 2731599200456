import {socialConstants} from "../constants";

const initialState = {youtube: null, selectedYoutube: null, instagram: null, selectedInstagram: null, blog: null};

export default function initReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case socialConstants.GET_YOUTUBE_SUCCESS:
            return {
                ...state,
                youtube: payload
            };
        case socialConstants.GET_YOUTUBE_FAIL:
            return {
                ...state,
                youtube: null
            };
        case socialConstants.SET_SELECTED_YOUTUBE_VIDEO:
            return {
                ...state,
                selectedYoutube: payload
            };
        case socialConstants.GET_INSTAGRAM_SUCCESS:
            return {
                ...state,
                instagram: payload
            };
        case socialConstants.GET_INSTAGRAM_FAIL:
            return {
                ...state,
                instagram: null
            };
        case socialConstants.SET_SELECTED_INSTAGRAM:
            return {
                ...state,
                selectedInstagram: payload
            };
        case socialConstants.GET_BLOG_SUCCESS:
            return {
                ...state,
                blog: payload
            };
        case socialConstants.GET_BLOG_FAIL:
            return {
                ...state,
                blog: null
            };
        default:
            return state;
    }
}