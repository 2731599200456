import {cartConstants} from "../constants";
import {alert} from "../../shared/Alert/Alert";

const initialState = {
    cart: {
        _id: 0,
        odemeSN: 0,
        sepetToplam: 0,
        kampanyasizlarinToplami: 0,
        indirimToplam: 0,
        cuzdan: 0,
        lock: 0,
        urunler: [],
        testerlar: [],
        kampanyalar: [],
        kupon: 0,
        kargoPrice: 0,
        kargo: 0,
    }
}


export default function cartReducer(state = initialState, action) {
    const {type, payload} = action;
    let product = payload?.product;
    const isTester = payload?.isTester;
    const products = payload?.products;
    const result = payload?.result;

    switch (type) {
        case cartConstants.GET_CART:
            if (products) {
                const testers = state.cart.testerlar ? state.cart.testerlar : [];
                let cart;

                if (!payload.mini) {
                    cart = {
                        ...result,
                        testerlar: [...testers],
                        urunler: Object.entries(result.urunler ? result.urunler : []).map((e) => (Object.assign({
                            "adet": e[1].adet,
                            "kmpBilgi": e[1].kmpBilgi,
                            "sf": e[1].satisFiyati,
                            "indirim": e[1].indirim,
                        }, products?.find(p => p._id === Number(e[0]))))),
                        kampanyalar: Object.entries(result.kampanyalar ? result.kampanyalar : []).map((e) => ({
                            "kmp_no": Number(e[0]),
                            "indirim": e[1].indirim,
                        })),
                        kupon: result.kupon
                    };
                } else {
                    cart = {
                        testerlar: [...testers],
                        urunler: Object.entries(result).map((e) => (Object.assign({
                                "adet": e[1].adet
                            }, products?.find(p => p._id === Number(e[0]))))
                        )
                    };
                }
                return {
                    cart: cart
                }
            } else {
                return state;
            }
        case cartConstants.ADD_TO_CART:
            if (!isTester) {
                let exists = state.cart.urunler.find(item => product?._id === item._id)
                if (exists) {
                    const adet = exists.adet + payload.adet;
                    let new_array = state.cart.urunler.map(element => element._id === exists._id ? {
                        ...element,
                        adet: adet
                    } : element);

                   // window.openModal("addSuccessModal")

                    return {
                        cart: {
                            ...state.cart,
                            sepetToplam: state.sepetToplam + product?.sf,
                            urunler: [...new_array],
                            testerlar: [...state.cart.testerlar]
                        }
                    }
                } else {
                    product.adet = payload.adet;

                  //  window.openModal("addSuccessModal")

                    return {
                        cart: {
                            ...state.cart,
                            sepetToplam: state.sepetToplam + product?.sf,
                            urunler: [...state.cart.urunler, product],
                            testerlar: [...state.cart.testerlar]
                        }
                    }
                }
            } else {
                const sumQuantity = state.cart.testerlar?.reduce((n, {adet}) => n + adet, 0);
                    if ( sumQuantity + payload.adet > 3 ) {

                    alert("warning", "En fazla 3 adet tester seçimi yapabilirsiniz!");
                    return state
                }

                let exists = state.cart.testerlar.find(item => product?._id === item._id)



                if (exists) {

console.log(exists)
                    const adet = exists.adet + payload.adet;

                    let new_array = state.cart.testerlar.filter(element =>
                    {


                        if(element._id === exists._id){

                            console.log(element._id,exists._id,adet)

                            if(adet >0){
                                element.adet=adet;
                            }

                    }
                      return element

                    })


                    alert("success", "Ürün başarıyla sepetinize eklenmiştir!")

                    return {
                        cart: {...state.cart, urunler: [...state.cart.urunler], testerlar: [...new_array]},
                    }
                } else {
                    product.adet = payload.adet;

                    alert("success", "Ürün başarıyla sepetinize eklenmiştir!")

                    return {
                        cart: {
                            ...state.cart,
                            urunler: [...state.cart.urunler],
                            testerlar: [...state.cart.testerlar, product]
                        }
                    }
                }
            }
        case cartConstants.REMOVE_FROM_CART:
            if (!isTester) {
                let new_items = state.cart.urunler.filter(item => product?._id !== item._id)
                let newTotal = state.sepetToplam - (product?.sf * product.adet);

                return {
                    cart: {
                        ...state.cart,
                        sepetToplam: newTotal,
                        testerlar: [...state.cart.testerlar],
                        urunler: new_items
                    }
                }
            } else {
                let new_items = state.cart.testerlar.filter(item => product?._id !== item._id)
                return {
                    cart: {...state.cart, urunler: [...state.cart.urunler], testerlar: new_items}
                }
            }
        case cartConstants.DELETE_CART: {
            return initialState;
        }
        case cartConstants.CHANGE_QUANTITY:
            let total = state.sepetToplam - (product?.sf * product.adet);
            product.adet = payload.adet;
            let sum = total + (product?.sf * product.adet);

            const productList = [...state.cart.urunler];
            const productIndex = productList.findIndex(a => a._id === product?._id);
            productList[productIndex] = product;

            return {
                cart: {...state.cart, sepetToplam: sum, urunler: productList, testerlar: [...state.cart.testerlar]},
            }
        default:
            return state;
    }
}
