import {combineReducers} from 'redux';
import registration from "./registration.reducer";
import authentication from "./auth.reducer";
import user from "./user.reducer";
import home from "./home.reducer";
import product from "./product.reducer";
import order from "./order.reducer";
import returned from "./return.reducer";
import store from "./store.reducer";
import menu from "./menu.reducer";
import social from "./social.reducer";

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartReducer from "./cart.reducer";
import location from "./location.reducer";
import payment from "./payment.reducer";
import footer from "./footer.reducer";


const persistConfig = {
    key: "misshaBasket",
    storage,
};

const cart = persistReducer(persistConfig, cartReducer);

export const rootReducer = combineReducers({
    authentication,
    registration,
    user,
    home,
    product,
    order,
    returned,
    store,
    menu,
    cart,
    location,
    payment,
    social,
    footer
});