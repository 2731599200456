import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {addToCart} from "../../store/actions/cart.actions";
import Stars from "../../utils/Stars";
import QuantityDropdown from "../Dropdowns/QuantityDropdown/QuantityDropdown";
import {getProductDetail} from "../../store/actions/product.actions";
import {BASIC_INGREDIENT_ID, PRODUCT_FUNCTION_ID, SKIN_TYPE_ID} from "../../constants/Constants";
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';


const QuickLookModal = ({
                            cdn,
                            product,
                            products,
                            productDetail,
                            productFilters,
                            campaigns,
                            isLoggedIn,
                            getProductDetail,
                            addToCart,
                            isMobile
                        }) => {

    const [numberOfProduct, setNumberOfProduct] = useState();

    useEffect(() => {
        if (product) {
            getProductDetail(product._id);
        }
    }, [product])

    const basicIngredients = productFilters?.find((pf) => pf._id === BASIC_INGREDIENT_ID);
    const productFunctions = productFilters?.find((pf) => pf._id === PRODUCT_FUNCTION_ID);
    const skinTypes = productFilters?.find((pf) => pf._id === SKIN_TYPE_ID);

    const [qSliderRef] = useKeenSlider(
        {
            loop: false,
            mode: "free-snap",
            slides: {
                perView: 1,
                spacing: 7,
            },
        },
        [

            // add plugins here
        ]
    )


    if(cdn && product){
    return (

     <div className="modal" tabIndex="-1" id="quickLookModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title d-flex align-items-center justify-content-center">
                            <i className="ri-eye-fill ri-1x mr-1 text-danger"/>
                            Hızlı Bakış
                        </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="productDetail my-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="productDetail-Gallery">
                                            <div className="keen-slider" ref={qSliderRef}>

                                                { [...Array(product?.rAdet)].map((e, i) => {
                                                    return (
                                                            <div className="keen-slider__slide image-wrapper" key={i}>
                                                                <>
                                                                    {i === 0 && (
                                                                        <img
                                                                            src={`${cdn}/urunler/${product?._id}/prd/D${product?._id}.jpg`}
                                                                            className="hover-zoom"
                                                                            alt=""/>
                                                                    )}
                                                                    {i > 0 && <img
                                                                        src={`${cdn}/urunler/${product?._id}/prd/D${product?._id}-${i}.jpg`}
                                                                        className="hover-zoom"
                                                                        alt=""/>}
                                                                </>
                                                            </div>
                                                    );
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-3 my-md-0">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-12 productDetail-SubContent">
                                                    <div className="row ">
                                                        <div className="col text-muted">
                                                            <small className="productDetail-SubContent-ProductCode">Ürün
                                                                Kodu:
                                                                {product?.urun_kod}</small>
                                                            <span>|</span>
                                                            <small
                                                                className="productDetail-SubContent-ProductCount">Miktar: {productDetail?.miktar}</small>
                                                            <a href="#."/>
                                                        </div>
                                                        <div className="col-12 col-md-auto pr-md-0">
                                                            <div className="d-flex justify-content-end ">
                                                                <a  onClick={(e) => e.preventDefault()}
                                                                   className="productDetail-SubContent-LikeBox d-flex flex-column align-items-center justify-content-center">
                                                                    <i className="ri-heart-fill"/>
                                                                    <small className="text-center mt-1">Bu Ürün <br/>
                                                                        <span>{product && product.fs}</span> <br/>Kişinin
                                                                        Favorisinde</small>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" col-12">
                                                    <h1 className=" mb-3 font-weight-bold">{product?.marka}{product?.ad}</h1>
                                                    <div className=" productDetail-Vote">
                                                        <div className=" d-flex justify-content-between justify-content-sm-start
                                                                         align-items-center">
                                                            <div>
                                                                <Stars n={product && product?.y}
                                                                       classes={'text-warning'}/>
                                                                <small className="font-weight-bold text-muted">
                                                                    ({productDetail?.commentStat?.sum} yorum)
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" productDetail-ongoingOpportunities">
                                                        <hr className=" mt-0"/>
                                                        <p className=" mb-2 mt-3 font-weight-normal">Devam Eden
                                                            Fırsatlar</p>
                                                        <div className="d-flex flex-row">
                                                            {campaigns && campaigns.filter(cp => cp._id === product?.uKRef || cp._id === product?.sKRef).map((cp, i) => {
                                                                return (
                                                                    <div
                                                                        className="productDetail-ongoingOpportunities-Item"
                                                                        key={i}>
                                                                        {cp.kmp_adi}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                </div>
                                                <div className="col-12 productDetail-Price">
                                                    {(product?.kmpBilgi.k && product?.kmpBilgi.s) ? (
                                                        <>
                                                            <a  onClick={(e) => e.preventDefault()}
                                                               className="mr-2 productDetail-Price-Old h3">
                                                                <del>{product?.sf}₺</del>
                                                            </a>
                                                            <a  onClick={(e) => e.preventDefault()}
                                                               className="mr-2 productDetail-Price-Default h3">
                                                                <del>{(product?.sf - product?.kmpBilgi.ki).toFixed(2)}₺</del>
                                                            </a>
                                                            <a  onClick={(e) => e.preventDefault()}
                                                               className="productDetail-Price-Discounted text-danger font-weight-bold h3 mb-0">
                                                                {(product?.sf - (product?.kmpBilgi.ki + product?.kmpBilgi.si)).toFixed(2)}₺
                                                            </a>
                                                        </>

                                                    ) : (

                                                        <>
                                                            {product?.kmpBilgi.k ? (
                                                                <>
                                                                    <a  onClick={(e) => e.preventDefault()}
                                                                       className="mr-2 productDetail-Price-Old h3">
                                                                        <del>{product?.sf}₺</del>
                                                                    </a>
                                                                    <a  onClick={(e) => e.preventDefault()}
                                                                       className="productDetail-Price-Discounted text-danger font-weight-bold h3 mb-0">
                                                                        {(product?.sf - product?.kmpBilgi.ki).toFixed(2)}₺
                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {product?.kmpBilgi.s ? (
                                                                        <>
                                                                            <a  onClick={(e) => e.preventDefault()}
                                                                               className="mr-2 productDetail-Price-Old h3">
                                                                                <del>{product?.sf}₺</del>
                                                                            </a>
                                                                            <a  onClick={(e) => e.preventDefault()}
                                                                               className="productDetail-Price-Discounted text-danger font-weight-bold h3 mb-0">
                                                                                {(product?.sf - product?.kmpBilgi.si).toFixed(2)}₺
                                                                            </a>
                                                                        </>
                                                                    ) : (
                                                                        <a  onClick={(e) => e.preventDefault()}
                                                                           className="productDetail-Price-Discounted text-danger font-weight-bold h3 mb-0">
                                                                            {product?.sf}₺
                                                                        </a>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-12 my-3">
                                                    <div className="row">
                                                        <div className="col-12 col-md-auto">
                                                            <div className="form-group input-group ">
                                                                <label htmlFor="a">
                                                                    <i className="ri-arrow-down-s-line"/>
                                                                </label>
                                                                <QuantityDropdown
                                                                    quantityChanged={(e) => setNumberOfProduct(e.target.value)}
                                                                    quantity={numberOfProduct}
                                                                    maxNumber={product?.stok > 10 ? 10 : product?.stok}
                                                                    classes={"custom-select custom-select-lg"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`col-12 col-md-6 ${isMobile && 'mt-3'}`}>
                                                            <button type="button" name="" id=""
                                                                    className="btn btn-warning btn-block"
                                                                    onClick={(e) => {

                                                                        let buttoncart = e.target;
                                                                        buttoncart.innerText ='bekleyin...';
                                                                        buttoncart.classList.remove('btn-warning')
                                                                        buttoncart.classList.add('btn-success');
                                                                        buttoncart.style.pointerEvents = 'none';
                                                                        addToCart(product, products, numberOfProduct, false, isLoggedIn, campaigns, false).then(()=>{
                                                                            buttoncart.innerText ='Sepete Eklendi';
                                                                        });
                                                                        setTimeout(() => {
                                                                            buttoncart.innerText ='Sepete Ekle';
                                                                            buttoncart.classList.remove('btn-success')
                                                                            buttoncart.classList.add('btn-warning');
                                                                            buttoncart.style.pointerEvents = 'auto';
                                                                            window.closeModal('quickLookModal')
                                                                        }, 1000);
                                                                    }}>
                                                                <i className=" ri-shopping-cart-line"/>
                                                                Sepete Ekle
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container my-3">
                                <h3>{product?.tr}</h3>
                                <p>{product?.kisaca}</p>
                            </div>
                            <div className="container py-4 py-md-0 mt-0">
                                <div className="row">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <th scope="row">Temel İçerikler:</th>
                                            <td> {basicIngredients?.filtreDegerler
                                                ?.filter((item) => product?.fl.includes(item.fd_id))
                                                .map((t) => t.fd_ad)
                                                .join()}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Ürün Fonksiyonları:</th>
                                            <td>{productFunctions?.filtreDegerler
                                                ?.filter((item) => product?.fl.includes(item.fd_id))
                                                .map((t) => t.fd_ad)
                                                .join()}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cilt Tipleri:</th>
                                            <td>{skinTypes?.filtreDegerler
                                                ?.filter((item) => product?.fl?.includes(item.fd_id))
                                                .map((t) => t.fd_ad)
                                                .join()}
                                                &nbsp;
                                                <img src="/assets//img/normal.png" alt=""/>
                                                &nbsp;
                                                <img src="/assets//img/karma.png" alt=""/>
                                                &nbsp;
                                                <img src="/assets//img/kuru.png" alt=""/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
}


const mapDispatchToProps = {
    addToCart,
    getProductDetail
};

const mapStateToProps = (state) => {
    return {
        cdn: state.menu?.mainConstants?.cdn,
        product: state.product.quickLookModalData?.product,
        products: state.product.products,
        productFilters: state.product.productFilters,
        isLoggedIn: state.authentication.isLoggedIn,
        productDetail: state.product.productDetail,
        campaigns: state.product.campaigns?.data,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLookModal);
