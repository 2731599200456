import {userConstants} from "../constants";
import UserService from "../../services/user.service";
import {alert} from "../../shared/Alert/Alert";

export const getUserProfile = () => (dispatch) => {
    return UserService.getUserProfile().then(
        (result) => {
            dispatch({
                type: userConstants.GET_USER_PROFILE_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_USER_PROFILE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const updateUserProfile = (data) => (dispatch) => {
    return UserService.updateUserProfile(data).then(
        (result) => {
            dispatch({
                type: userConstants.UPDATE_USER_PROFILE_SUCCESS,
                payload: data
            });
            return Promise.resolve(data);

        }, (error) => {

            dispatch({
                type: userConstants.UPDATE_USER_PROFILE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const forgotPass = (email, phone) => (dispatch) => {
    return UserService.forgotPass(email, phone).then(
        (result) => {

            if(result.success === true){
                dispatch({
                    type: userConstants.FORGOT_PASSWORD_SUCCESS,
                });
                alert("success", result.response)
                return Promise.resolve(result);
            }else{

                alert("error", result.response)
                dispatch({
                    type: userConstants.FORGOT_PASSWORD_FAIL,
                });
                return Promise.reject(result.response);
            }


        }, (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.response) ||
                error.message ||
                error.toString();

            dispatch({
                type: userConstants.FORGOT_PASSWORD_FAIL,
            });

            alert("error", message)

            return Promise.reject();
        }
    );
};

export const userFavorites = () => (dispatch) => {
    return UserService.getUserFav().then(
        (result) => {
            dispatch({
                type: userConstants.GET_ALL_FAVORITES_SUCCESS,
                payload: result?.favorilerim
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_ALL_FAVORITES_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setFavorite = (productId) => (dispatch) => {
    dispatch({
        type: userConstants.SET_FAVORITE_SUCCESS,
        payload: productId
    });
    return UserService.setUserFav(productId).then(
        (result) => {
            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.SET_FAVORITE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const deleteFavorite = (productId) => (dispatch) => {

    return UserService.deleteUserFav(productId).then(
        (result) => {
            dispatch({
                type: userConstants.DELETE_FAVORITE_SUCCESS,
                payload: productId
            });
            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.DELETE_FAVORITE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const updatePassword = ({old_pass, new_pass}) => (dispatch) => {
    return UserService.updatePassword({old_pass, new_pass}).then(
        (result) => {
            alert("success", "Şifreniz başarıyla güncellenmiştir!")
            return Promise.resolve();
        }, (error) => {
            return Promise.reject();
        }
    );
};

export const getCoupons = () => (dispatch) => {
    return UserService.getCoupons().then(
        (result) => {
            dispatch({
                type: userConstants.GET_COUPONS_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_COUPONS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const checkCouponCode = (code) => (dispatch) => {
    return UserService.checkCouponCode(code).then(
        (result) => {
            dispatch({
                type: userConstants.CHECK_COUPON_CODE_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.CHECK_COUPON_CODE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getPoints = () => (dispatch) => {

    return UserService.getPoints().then(
        (result) => {
            dispatch({
                type: userConstants.GET_POINTS_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_POINTS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const createCoupon = () => (dispatch) => {
    return UserService.createCoupon().then(
        (result) => {

            dispatch({
                type: userConstants.CREATE_COUPON_SUCCESS,
                payload: result?.data
            });
            alert(result.type,result.message)
            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.CREATE_COUPON_FAIL,
            });
            alert('alert','Kupon oluşturulurken bir hata ile karşılaşıldı. Lütfen Sayfayı yenileyerek tekrar deneyiniz');

            return Promise.reject();
        }
    );
};

export const getBeautyProfile = () => (dispatch) => {
    return UserService.getBeautyProfile().then(
        (result) => {
            dispatch({
                type: userConstants.GET_BEAUTY_PROFILE_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_BEAUTY_PROFILE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const setBeautyProfile = (data) => (dispatch) => {
    return UserService.setBeautyProfile(data).then(
        (result) => {
            dispatch({
                type: userConstants.SET_BEAUTY_PROFILE_SUCCESS,
                payload: result
            });

            if (result.status === 200) {
                alert("success", "Profiliniz başarıyla kaydedilmiştir!")
                window.closeModal("beautyModal");
            }
            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.SET_BEAUTY_PROFILE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getUserBeautyProfile = () => (dispatch) => {
    return UserService.getUserBeautyProfile().then(
        (result) => {
            dispatch({
                type: userConstants.GET_USER_BEAUTY_PROFILE_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_USER_BEAUTY_PROFILE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const passwordValidCode = (data) => (dispatch) => {
    return UserService.passwordValidCode(data).then(
        (result) => {
            dispatch({
                type: userConstants.PASSWORD_VALID_CODE_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.PASSWORD_VALID_CODE_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getPreferences = () => (dispatch) => {
    return UserService.getPreferences().then(
        (result) => {
            dispatch({
                type: userConstants.GET_PREFERENCES_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_PREFERENCES_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const savePreferences = (data) => (dispatch) => {
    dispatch({
        type: userConstants.SAVE_PREFERENCES_SUCCESS,
        payload: data
    });
    return UserService.savePreferences(data).then(
        (result) => {
            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.SAVE_PREFERENCES_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getAskedQuestions = () => (dispatch) => {
    return UserService.getAskedQuestions().then(
        (result) => {
            dispatch({
                type: userConstants.GET_ASKED_QUESTIONS_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_ASKED_QUESTIONS_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getContract = () => (dispatch) => {
    return UserService.getContract().then(
        (result) => {
            dispatch({
                type: userConstants.GET_CONTRACT_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_CONTRACT_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getVerify = () => (dispatch) => {

    return UserService.getVerify().then(
        (result) => {
            dispatch({
                type: userConstants.GET_CONTRACT_SUCCESS,
                payload: result
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: userConstants.GET_CONTRACT_FAIL,
            });

            return Promise.reject();
        }
    );
};



