import {footerConstants} from "../constants";

const initialState = {page: null};

export default function footerReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case footerConstants.GET_PAGE_SUCCESS:
            return {
                ...state,
                page: payload
            };
        case footerConstants.GET_PAGE_FAIL:
            return {
                ...state,
                page: null
            };
        default:
            return state;
    }
}