import MenuService from "../../services/menu.service";
import ProductService from "../../services/product.service";
import {menuConstants} from "../constants";


export const getMenu = (isLoggedIn) => (dispatch) => {
    return MenuService.getMenus().then(
        (result) => {
            if (isLoggedIn) {
                ProductService.getUserInit().then((data) => {
                    dispatch({
                        type: menuConstants.GET_MENU_SUCCESS,
                        payload: {...result, userInit: data.userInit},
                    })
                })
            } else {
                dispatch({
                    type: menuConstants.GET_MENU_SUCCESS,
                    payload: result,
                });
            }

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: menuConstants.GET_MENU_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getMobileMenu = () => (dispatch) => {
    return MenuService.getMobileMenu().then(
        (result) => {
            dispatch({
                type: menuConstants.GET_MOBILE_MENU_SUCCESS,
                payload: result,
            });

            return Promise.resolve();
        }, (error) => {

            dispatch({
                type: menuConstants.GET_MOBILE_MENU_FAIL,
            });

            return Promise.reject();
        }
    );
};


export const toggleMiddleMenu = () => {
    return {
        type: menuConstants.TOGGLE_MIDDLE_MENU,
    }
}

export const showMobileFooter = (payload) => {

    return {
        type: menuConstants.SHOW_MOBILE_FOOTER,
        payload: payload,
    }

}

export const setSelectedPageMenuTitle = (data) => {
    return {
        type: menuConstants.SET_SELECTED_PAGE_MENU_TITLE,
        payload: data,
    }
}

export const setActiveProfileTab = (data) => {
    return {
        type: menuConstants.SET_ACTIVE_PROFILE_TAB,
        payload: data,
    }
}