export const CardSaveModal = () => {
    return (
        <div className="modal fade" id="cardSaveModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                id="cardSaveDismissBtn">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-center">
                        <h4>Dilerseniz, daha hızlı alışveriş yapabilmek içn kartınızı
                            kaydedin! </h4>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="col-md-6">
                            <button type="button" className="btn btn-md btn-danger btn-block" id="cardSaveOkBtn">
                                Tamam
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}