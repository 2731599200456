import http from "../utils/HttpCommon";

class HomeService {
    getHomePage() {
        return http.post("/home").then((result) => {
            return result.data.response;
        });
    }
}

export default new HomeService();